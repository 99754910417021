<div [ngSwitch]="reason">
    <div *ngSwitchCase="'no-permissions'" class="middle-box text-center animated fadeInDown">
        <h1>403</h1>
        <h3 class="font-bold">{{ 'Common_Error_Forbidden_NoPermissions' | translate }}</h3>
        <div class="error-desc">{{ 'Common_Error_Forbidden_NoPermissionsDesc' | translate }}</div>
    </div>

    <div *ngSwitchDefault class="middle-box text-center animated fadeInDown">
        <h1>403</h1>
        <h3 class="font-bold">{{ 'Common_Error_Forbidden' | translate }}</h3>
        <div class="error-desc"></div>
    </div>
</div>
