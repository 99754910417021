import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent implements OnInit {
    public reason: string;

    constructor(private readonly title: Title, private readonly translate: TranslateService, private readonly route: ActivatedRoute) {}

    ngOnInit() {
        this.reason = this.route.snapshot.queryParams['reason'] || null;
        this.translate.get('Common_Error_ForbiddenTitle').subscribe((res) => {
            this.title.setTitle(res);
        });
    }
}
