import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent
    }
];

@NgModule({
    imports: [ComponentsModule, TranslateModule, RouterModule.forChild(routes)],
    exports: [],
    declarations: [NotFoundComponent, ForbiddenComponent],
    providers: []
})
export class ErrorsModule {}
