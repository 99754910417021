import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
    constructor(private readonly title: Title, private readonly translate: TranslateService) {}
    async ngOnInit() {
        this.title.setTitle(await this.translate.get('Common_Error_NotFoundTitle').toPromise());
    }
}
